<template>
    <div class="google-sheets-pattern">
        <IntegrationComponent
            ref="integration"
            :integration="integration"
            @integration-message="handleMessage"
            @integration-select-account="handleSelectAccount"
            @integration-activate="handleActivate"
            @integration-close="handleClose"
        >
            <template #icon>
                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54">
                    <g fill="none">
                        <path
                            fill="#23A566"
                            d="M46.975 50.879a3.11 3.11 0 01-.919 2.207 3.143 3.143 0 01-2.218.914H11.134c-.831 0-1.628-.33-2.216-.914A3.113 3.113 0 018 50.879V3.134a3.128 3.128 0 013.134-3.12h21.029l14.812 15.102v35.763z"
                        />
                        <path
                            fill="#1C8F5A"
                            d="M33.795 14.732l13.18 12.535-.04-12.17H35.272a3.13 3.13 0 01-1.468-.363h-.008v-.002h-.001z"
                        />
                        <path
                            fill="#8ED1B1"
                            d="M46.975 15.096H35.27a3.128 3.128 0 01-3.134-3.12V0l14.837 15.096h.002z"
                        />
                        <path
                            fill="#FFF"
                            d="M37.622 44.014h-20.27V26.538h20.271v17.476h-.001zM28.74 29.035v3.12h6.374v-3.12H28.74zm0 4.993v2.913h6.374v-2.913H28.74zm0 4.785v2.705h6.374v-2.706H28.74v.001zm-2.507 2.705v-2.706h-6.374v2.705h6.374v.001zm0-4.577v-2.913h-6.374v2.913h6.374zm0-4.786v-3.12h-6.374v3.12h6.374z"
                        />
                    </g>
                </svg>
            </template>
            <template #options>
                <DropdownItemComponent
                    class="dropdown-item-component"
                    divided
                    @click="handleOpenSheet"
                >
                    <i
                        class="dropdown-item-component__icon dropdown-item-component__icon--file-text"
                    /><span>View spreadsheet</span></DropdownItemComponent
                >
            </template>
            <template #configure>
                <div class="google-sheets-pattern__step">
                    <h2>Choose a spreadsheet</h2>
                    <p>Where do you want to send your responses?</p>
                    <RowComponent justify="center">
                        <ButtonComponent
                            class="button-component--icon"
                            :type="newSheetType"
                            shadow
                            @click="handleNewSheet"
                        >
                            <i class="button-component__icon button-component__icon--file-text" />
                            <span>New sheet</span>
                        </ButtonComponent>
                        <ButtonComponent
                            class="button-component--icon"
                            :type="existingSheetType"
                            shadow
                            @click="handleExistingSheet"
                            ><i class="button-component__icon button-component__icon--link" />
                            <span>Existing sheet</span>
                        </ButtonComponent>
                    </RowComponent>
                    <transition name="fade-slide-in">
                        <RowComponent v-if="sheetOption" justify="center">
                            <template v-if="newSheetSelected">
                                <InputComponent
                                    v-model="sheetName"
                                    label="Name your spreadsheet:"
                                    placeholder="E.g. Survey results"
                                    @keyup.enter="handleCreate"
                                >
                                    <template #append>
                                        <ButtonComponent
                                            type="info"
                                            shadow
                                            :disabled="!sheetName"
                                            @click="handleCreate"
                                        >
                                            <i
                                                accesskey=""
                                                class="button-component__icon button-component__icon--file-plus"
                                            />
                                            <span>Create</span>
                                        </ButtonComponent>
                                    </template>
                                </InputComponent>
                            </template>
                            <template v-else-if="existingSheetSelected">
                                <InputComponent
                                    v-model="sheetURL"
                                    label="Paste your spreadsheet URL:"
                                    placeholder="https://docs.google.com/spreadsheets/d/..."
                                    @keyup.enter="handleConnect"
                                >
                                    <template #append>
                                        <ButtonComponent
                                            type="info"
                                            shadow
                                            :disabled="!sheetURL || !isValidSheetURL"
                                            @click="handleConnect"
                                        >
                                            <i
                                                accesskey=""
                                                class="button-component__icon button-component__icon--link-2"
                                            />
                                            <span>Connect</span>
                                        </ButtonComponent>
                                    </template>
                                </InputComponent>
                            </template>
                        </RowComponent>
                    </transition>
                    <transition name="fade-slide-in">
                        <div v-if="createError" class="google-sheets-pattern__error">
                            <i
                                class="google-sheets-pattern__error__icon google-sheets-pattern__error__icon--alert-triangle"
                            />
                            <span>{{ createError }}</span>
                        </div>
                    </transition>
                </div>
            </template>
        </IntegrationComponent>
    </div>
</template>

<script>
import IntegrationService from '@/services/integration-service'
import { mapGetters } from 'vuex'

export default {
    name: 'GoogleSheetsPattern',

    data() {
        return {
            integration: {
                name: 'Google Sheets',
                description:
                    'Automatically send all your collected data straight to a Google Sheet and let it sync as results come in.'
            },
            integrationId: '',
            sheetOption: '',
            sheetName: '',
            sheetURL: '',
            sheetId: '',
            createError: ''
        }
    },

    computed: {
        ...mapGetters({
            integrations: 'integrations/integrations'
        }),

        slug() {
            return this.integration.name
                .toLowerCase()
                .trim()
                .replace(/[\s\W-]+/g, '-')
        },

        integrationData() {
            return this.integrations.find((integration) => {
                return (
                    integration.type === this.slug &&
                    integration.survey_id === this.$route.params.id
                )
            })
        },

        newSheetSelected() {
            return this.sheetOption === 'newSheet'
        },

        existingSheetSelected() {
            return this.sheetOption === 'existingSheet'
        },

        newSheetType() {
            return this.newSheetSelected ? 'default' : 'info'
        },

        existingSheetType() {
            return this.existingSheetSelected ? 'default' : 'info'
        },

        isValidSheetURL() {
            const regex = new RegExp('/spreadsheets/d/([a-zA-Z0-9-_]+)')

            return regex.test(this.sheetURL)
        }
    },

    methods: {
        async handleMessage(data) {
            const { id } = await this.$store.dispatch('integrations/addAccount', {
                type: 'google-sheets',
                email: data.email,
                access_token: data.access_token,
                refresh_token: data.refresh_token,
                scope: data.scope,
                api_endpoint: data.api_endpoint,
                expires_in: data.expires_in
            })

            this.integrationId = id

            this.$refs.integration.next()
        },

        handleSelectAccount(id) {
            this.integrationId = id

            this.$refs.integration.next()
        },

        async handleActivate() {
            await this.$store.dispatch('integrations/addIntegration', {
                type: this.slug,
                survey_id: this.$route.params.id,
                data: {
                    integration_id: this.integrationId,
                    settings: {
                        sheet_id: this.sheetId
                    }
                }
            })

            this.$refs.integration.next()

            setTimeout(() => {
                this.$refs.integration.close()
            }, 400)
        },

        handleOpenSheet() {
            const url = `https://docs.google.com/spreadsheets/d/${this.integrationData.settings.sheet_id}`

            window.open(url, '_blank')
        },

        handleNewSheet() {
            this.sheetOption = 'newSheet'
        },

        handleExistingSheet() {
            this.sheetOption = 'existingSheet'
        },

        async handleCreate() {
            try {
                const { data } = await IntegrationService.addGoogleSheetSheet({
                    id: this.integrationId,
                    data: {
                        title: this.sheetName
                    }
                })

                this.sheetId = data

                this.$refs.integration.next()
            } catch (error) {
                const { response } = error
                if (response.status === 401) {
                    this.createError = `Unauthorized access to selected ${this.integration.name} account.`
                }
            }
        },

        handleConnect() {
            const regex = new RegExp('/spreadsheets/d/([a-zA-Z0-9-_]+)')
            const sheetId = regex.exec(this.sheetURL)

            this.sheetId = sheetId[1]

            this.$refs.integration.next()
        },

        handleClose() {
            Object.assign(this.$data, this.$options.data.apply(this))
        }
    }
}
</script>

<style lang="scss">
.google-sheets-pattern {
}

.google-sheets-pattern__step {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: 0 160px;

    .row-component {
        margin-top: 50px;
    }

    .button-component:not(:first-child) {
        margin-left: 15px;
    }

    .button-component--info {
        color: #34495e;

        background-color: #fff;

        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;

        &:hover {
            background-color: #fff;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
        }
    }

    .button-component--default {
        .button-component__icon--file-text,
        .button-component__icon--link {
            background-color: #fff;
        }
    }

    .input-component {
        .button-component--info {
            position: relative;

            padding: 12px 16px 12px 14px;

            border-radius: 4px;
        }
    }

    .button-component__icon--file-text {
        @include mask-icon('~@/assets/svg/icons/file-text.svg');

        background-color: #34495e;

        transition: all 0.2s ease;
    }

    .button-component__icon--file-plus {
        @include icon('~@/assets/svg/icons/file-plus.svg');
    }

    .button-component__icon--link {
        @include mask-icon('~@/assets/svg/icons/link.svg');

        background-color: #34495e;

        transition: all 0.2s ease;
    }

    .button-component__icon--link-2 {
        @include icon('~@/assets/svg/icons/link-2.svg');
    }

    .input-component {
        max-width: 600px;
    }

    .fade-slide-in-enter-active,
    .fade-slide-in-leave-active {
        transition: all 0.4s ease;
    }

    .fade-slide-in-enter,
    .fade-slide-in-leave-to {
        opacity: 0;
        transform: translateY(10%);
    }
}

.google-sheets-pattern__error {
    display: flex;

    align-items: center;

    color: #f75851;

    font-size: 14px;

    white-space: nowrap;

    margin-top: 50px;
}

.google-sheets-pattern__error__icon {
    display: inline-block;

    width: 16px;
    height: 16px;

    margin: -2px 8px 0 0;

    background-size: contain;
}

.google-sheets-pattern__error__icon--alert-triangle {
    @include icon('~@/assets/svg/icons/alert-triangle.svg');
}
</style>
