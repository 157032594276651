<template>
    <div class="integrations-view">
        <CardComponent>
            <LoadingComponent
                v-show="loading"
                stroke-color="#579ae6"
                overlay="rgba(255, 255, 255, 0.8)"
            />
            <template #header>
                <RowComponent align="center">
                    <span>Integrations</span>
                </RowComponent>
            </template>
            <MailchimpPattern />
            <GoogleSheetsPattern />
            <SalesforcePattern />
        </CardComponent>
        <Teleport to="body" />
    </div>
</template>

<script>
import MailchimpPattern from '@/patterns/integrations/Mailchimp'
import GoogleSheetsPattern from '@/patterns/integrations/GoogleSheets'
import SalesforcePattern from '@/patterns/integrations/Salesforce'
import { mapGetters } from 'vuex'

export default {
    name: 'Integrations',

    components: {
        MailchimpPattern,
        GoogleSheetsPattern,
        SalesforcePattern
    },

    metaInfo: {
        title: 'Integrations'
    },

    data() {
        return {
            loading: false
        }
    },

    computed: {
        ...mapGetters({
            permissions: 'auth/permissions'
        })
    },

    async created() {
        this.loading = true

        await this.$store.dispatch('integrations/fetchAccounts')
        await this.$store.dispatch('integrations/fetchIntegrations', { id: this.$route.params.id })

        this.loading = false
    }
}
</script>

<style lang="scss">
.integrations-view {
    .loading-component {
        border-radius: 6px;
    }

    .card-component__header {
        border-bottom: none;
    }

    .card-component__content {
        padding: 0;
    }
}
</style>
