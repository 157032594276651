// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/svg/icons/arrow-right.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".salesforce-pattern__step[data-v-7323cf5e]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:relative;width:100%}.salesforce-pattern__step .button-component__icon--arrow-right[data-v-7323cf5e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat}.salesforce-pattern__step__inner[data-v-7323cf5e]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;width:100%;padding:0 160px;will-change:transform}.salesforce-pattern__step__inner .row-component[data-v-7323cf5e]{margin-top:50px}.salesforce-pattern__step__inner--map .row-component[data-v-7323cf5e]{margin-top:0}.salesforce-pattern__step__inner--map p[data-v-7323cf5e]{margin-bottom:40px}.salesforce-pattern__step__inner--map .divider-component[data-v-7323cf5e]{width:30px}.salesforce-pattern__step__inner--map .row-component[data-v-7323cf5e]:last-child{margin-top:40px}", ""]);
// Exports
module.exports = exports;
